/* You can add global styles to this file, and also import other style files */

@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

html,
body {
  height: 100%;

  overflow: hidden;
  /* Hide scrollbars */
}

body {
  margin: 0;
  font-family: Montserrat "Times New Roman", Times, serif;
}

.form-control {
  border: 2px solid #d9d9d9 !important;
  border-radius: 30px;
}

.btn-primary {
  font-family: "Montserrat";
  font-style: normal;
  background: #005266;
  border-radius: 30px;
  font-weight: 700;
  height: 50px;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  border: none;
}

.btn-primary:hover {
  background: #005266 !important;
}

.btn-primary:active {
  background: #005266 !important;
}

.btn-primary:focus {
  background: #005266 !important;
}

.btn:disabled {
  background: #cccccc;
  border: none;
}

.form-control:focus {
  border-color: #d9d9d9;
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:active,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  background-color: #ffffff;
}

.cursor {
  cursor: pointer;
}

::-webkit-input-placeholder {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #cccccc;
}

:-ms-input-placeholder {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #cccccc;
}

::placeholder {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #cccccc;
}

.carousel {
  width: 50%;
  margin: auto;
}

.carouselTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #111111;
}

.carouselSubtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #666666;
}

.learn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #21cabf;
  text-decoration: none;
}

.next {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #21cabf;
  text-decoration: none;
}

.learn:hover {
  color: #005266 !important;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.is-invalid {
  border: 2px solid #fc605b;
  border-radius: 30px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.container {
  padding: 0px;
}

.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #005266;
  margin-left: 10px;
}

.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #005266 !important;
  background-color: white !important;
  cursor: default;
}

.ngx-pagination a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #666666 !important;
}

.ngx-pagination {
  margin-bottom: 1rem;
  text-align: center;
}

.ngx-pagination .pagination-next a:after,
.ngx-pagination .pagination-next.disabled:after {
  content: "\2192" !important;
  display: inline-block;
  margin-left: 0.5rem;
  background: #005266;
  padding: 5px 7px;
  color: white;
  font: bold;
  border-radius: 50%;
}

.ngx-pagination .pagination-previous a:before,
.ngx-pagination .pagination-previous.disabled:before {
  content: none !important;
}

.profile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #111111;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(1, 58, 72, 0.2);
  border-radius: 5px;
  padding-top: 14px;
  height: 40px;
}

.notify {
  width: 46px;
  padding-top: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(1, 58, 72, 0.2);
  border-radius: 5px;
  height: 40px;
  text-align: center;
}

.sidebar.collapsed .sideNav {
  width: 80px !important;
}

.mat-tab-labels {
  background: #f1f3f4;
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #666666;
  height: 58px;
}

.mat-tab-label {
  font-family: "Montserrat";
  min-width: 190px !important;
  font-weight: 700;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background: #111111;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #111111;
}

.mat-tab-body-content {
  overflow: hidden !important;
  height: 500px !important;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type="text"],
.iti--allow-dropdown input[type="tel"],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type="text"],
.iti--separate-dial-code input[type="tel"] {
  border: none !important;
}

.mat-tab-label-container {
  font-family: "Montserrat" !important;
  border-radius: 10px 10px 0px 0px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 32px;
}

.mat-button-toggle-appearance-standard {
  background: none;
  border-radius: 24px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  height: 32px;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
  background: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
  border-left: none;
  width: 66px;
  padding: 0px;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: 0.5px solid #666666 !important;
  border-radius: 50px !important;
  padding: 3px;
  width: 235px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: black;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: black;
  background-color: black;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: black;
}

.mat-radio-button-focus-overlay {
  background-color: transparent !important;
}

.mat-radio-button {
  padding-right: 20px;
}

@media (min-width: 0px) and (max-width: 768px) {
  .mat-typography {
    overflow: scroll;
  }

  .title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #005266;
    margin-left: 10px;
  }
}

@media screen and (orientation: landscape) and (max-width: 910px) {
  .mat-typography {
    overflow: scroll;
  }

  .d-flex {
    display: block !important;
  }
}